import { createUseStyles } from 'react-jss';

export default createUseStyles({
  optionsContainer: {
    '@media (min-width: 768px)': {
      '&::-webkit-scrollbar': {
        width: '4px',
      },
    },
  },
});
