import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';
import { getLanguageFromNavigator, getTranslation } from '../../utils';

const defaultLanguage = getLanguageFromNavigator();
const loadTranslations = async () => {
  const translation = await getTranslation(defaultLanguage);
  const resources = {
    [defaultLanguage]: { translation },
  };

  i18next
    .use(LanguageDetector)
    .use(XHR)
    .init({
      fallbackLng: defaultLanguage,
      debug: true,
      ns: ['translation'],
      lng: defaultLanguage,
      interpolation: {
        escapeValue: false,
      },
      resources,
    });
};

export default loadTranslations;
