import { Avatar } from 'antd';
import { useState } from 'react';

interface InitialsStyle {
  color: string;
  backgroundColor: string;
}

const colors: InitialsStyle[] = [
  {
    color: '#DE7A16',
    backgroundColor: '#FDEACD',
  },
  {
    color: '#206864',
    backgroundColor: '#DADFBE80',
  },
  {
    color: '#206864',
    backgroundColor: '#1082B24D',
  },
  {
    color: '#5672E6',
    backgroundColor: '#DEE4F4',
  },
  {
    color: '#7B56E6',
    backgroundColor: '#EAE4EC',
  },
  {
    color: '#DE7A16',
    backgroundColor: '#FEE3DC',
  },
  {
    color: '#206864',
    backgroundColor: '#D8F3F1',
  },
  {
    color: '#37B24D',
    backgroundColor: '#DFF3E3',
  },
  {
    color: '#6B9CEB',
    backgroundColor: '#E3F2F8',
  },
];

const getInitialsStyle = (): InitialsStyle => {
  const min = 0;
  const max = 9;
  const initialsStyleNumber = Math.floor(Math.random() * (max - min + 1) + min);
  return colors[initialsStyleNumber];
};

const AvatarMemorial = ({
  image,
  initials,
  size,
  shape = 'circle',
}: {
  image: string;
  initials: string;
  size: 208 | 94 | 48 | 32;
  shape?: 'circle' | 'square';
}) => {
  const [initialsStyle] = useState<InitialsStyle>(getInitialsStyle());
  const [hasErrorTheImage, setHasErrorTheImage] = useState<boolean>(false);
  const fontSize: string =
    size === 32 ? '12.8px' : size === 48 ? '16px' : size === 94 ? '31.25px' : '96px';

  return (
    <>
      {image && !hasErrorTheImage ? (
        <Avatar
          size={size}
          src={image}
          style={{ minWidth: `${size}px` }}
          shape={shape}
          onError={() => {
            setHasErrorTheImage(true);
            return false;
          }}
        />
      ) : (
        <Avatar
          size={size}
          className="uppercase"
          style={{ ...initialsStyle, fontSize, minWidth: `${size}px` }}
          shape={shape}
        >
          {initials}
        </Avatar>
      )}
    </>
  );
};

export default AvatarMemorial;
