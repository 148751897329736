import { useEffect, useRef, useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
// import arabic from '../../../assets/images/flags/arabic.png';
import bulgarian from '../../../assets/images/flags/bulgarian.png';
import catalan from '../../../assets/images/flags/catalan.png';
import chinese from '../../../assets/images/flags/chinese.png';
import czech from '../../../assets/images/flags/czech.png';
import gaelic from '../../../assets/images/flags/gaelic.png';
import danish from '../../../assets/images/flags/danish.png';
import german from '../../../assets/images/flags/german.png';
import greek from '../../../assets/images/flags/greek.png';
import englishGb from '../../../assets/images/flags/english-gb.png';
import englishUs from '../../../assets/images/flags/english-us.png';
import spanish from '../../../assets/images/flags/spanish.png';
import vasco from '../../../assets/images/flags/vasco.png';
import finnish from '../../../assets/images/flags/finnish.png';
import frenchFr from '../../../assets/images/flags/french-fr.png';
import gallego from '../../../assets/images/flags/gallego.png';
import hungarian from '../../../assets/images/flags/hungarian.png';
import italian from '../../../assets/images/flags/italian.png';
import korean from '../../../assets/images/flags/korean.png';
import dutch from '../../../assets/images/flags/dutch.png';
import norwegian from '../../../assets/images/flags/norwegian.png';
import polish from '../../../assets/images/flags/polish.png';
import portugueseBr from '../../../assets/images/flags/portuguese-br.png';
import portuguesePt from '../../../assets/images/flags/portuguese-pt.png';
import romanian from '../../../assets/images/flags/romanian.png';
import russian from '../../../assets/images/flags/russian.png';
import swedish from '../../../assets/images/flags/swedish.png';
import turkish from '../../../assets/images/flags/turkish.png';
import ucranian from '../../../assets/images/flags/ucranian.png';
import japanese from '../../../assets/images/flags/japanese.png';
import irish from '../../../assets/images/flags/irish.png';
import valenciano from '../../../assets/images/flags/valenciano.png';
import { CheckSolid } from '../../../utils/icons';
import Search from '../../Search';
import useStyle from './index.styles';
import { SupportedLanguage } from '../../../types/SupportedLanguage';
import { getTranslation, hideGlobalSpinner, showGlobalSpinner } from '../../../utils';
import dayjs from 'dayjs';
import LanguageFlag from '../LanguageFlag';

type LanguageTitle =
  // | 'العربية'
  | 'български'
  | 'Català'
  | '中文'
  | 'Čeština'
  | 'Cymraeg'
  | 'Dansk'
  | 'Deutsch'
  | 'English GB'
  | 'English US'
  | 'Valencià'
  | 'Español ES'
  | 'Euskara'
  | 'Suomi'
  | 'Français'
  | 'Galego'
  | 'Magyar'
  | 'Italiano'
  | '한국어'
  | 'Nederlands'
  | 'Norsk'
  | 'Polski'
  | 'Português BR'
  | 'Português PT'
  | 'Română'
  | 'Русский'
  | 'Svenska'
  | 'Türkçe'
  | 'Українська'
  | '日本語'
  | 'Gaeilge'
  | 'Ελληνικά';
type MenuListType = {
  [key in SupportedLanguage]: { title: LanguageTitle; flag: string };
};

export const menuList: MenuListType = {
  // ar: {
  //   title: 'العربية',
  //   flag: arabic,
  // },
  bg: {
    title: 'български',
    flag: bulgarian,
  },
  ca: {
    title: 'Català',
    flag: catalan,
  },
  zh: {
    title: '中文',
    flag: chinese,
  },
  cs: {
    title: 'Čeština',
    flag: czech,
  },
  cy: {
    title: 'Cymraeg',
    flag: gaelic,
  },
  da: {
    title: 'Dansk',
    flag: danish,
  },
  de: {
    title: 'Deutsch',
    flag: german,
  },
  el: {
    title: 'Ελληνικά',
    flag: greek,
  },
  en_gb: {
    title: 'English GB',
    flag: englishGb,
  },
  en_us: {
    title: 'English US',
    flag: englishUs,
  },
  es_va: {
    title: 'Valencià',
    flag: valenciano,
  },
  es_es: {
    title: 'Español ES',
    flag: spanish,
  },
  eu: {
    title: 'Euskara',
    flag: vasco,
  },
  fi: {
    title: 'Suomi',
    flag: finnish,
  },
  fr: {
    title: 'Français',
    flag: frenchFr,
  },
  gl: {
    title: 'Galego',
    flag: gallego,
  },
  hu: {
    title: 'Magyar',
    flag: hungarian,
  },
  it: {
    title: 'Italiano',
    flag: italian,
  },
  ko: {
    title: '한국어',
    flag: korean,
  },
  nl: {
    title: 'Nederlands',
    flag: dutch,
  },
  no: {
    title: 'Norsk',
    flag: norwegian,
  },
  pl: {
    title: 'Polski',
    flag: polish,
  },
  pt_br: {
    title: 'Português BR',
    flag: portugueseBr,
  },
  pt_pt: {
    title: 'Português PT',
    flag: portuguesePt,
  },
  ro: {
    title: 'Română',
    flag: romanian,
  },
  ru: {
    title: 'Русский',
    flag: russian,
  },
  sv: {
    title: 'Svenska',
    flag: swedish,
  },
  tr: {
    title: 'Türkçe',
    flag: turkish,
  },
  uk: {
    title: 'Українська',
    flag: ucranian,
  },
  ja: {
    title: '日本語',
    flag: japanese,
  },
  ga: {
    title: 'Gaeilge',
    flag: irish,
  },
};

export const languageOptions: { id: SupportedLanguage; name: LanguageTitle }[] = [
  // { id: 'ar', name: 'العربية' },
  { id: 'bg', name: 'български' },
  { id: 'ca', name: 'Català' },
  { id: 'zh', name: '中文' },
  { id: 'cs', name: 'Čeština' },
  { id: 'cy', name: 'Cymraeg' },
  { id: 'da', name: 'Dansk' },
  { id: 'de', name: 'Deutsch' },
  { id: 'el', name: 'Ελληνικά' },
  { id: 'en_gb', name: 'English GB' },
  { id: 'en_us', name: 'English US' },
  { id: 'es_va', name: 'Valencià' },
  { id: 'es_es', name: 'Español ES' },
  { id: 'eu', name: 'Euskara' },
  { id: 'fi', name: 'Suomi' },
  { id: 'fr', name: 'Français' },
  { id: 'gl', name: 'Galego' },
  { id: 'hu', name: 'Magyar' },
  { id: 'it', name: 'Italiano' },
  { id: 'ko', name: '한국어' },
  { id: 'nl', name: 'Nederlands' },
  { id: 'no', name: 'Norsk' },
  { id: 'pl', name: 'Polski' },
  { id: 'pt_br', name: 'Português BR' },
  { id: 'pt_pt', name: 'Português PT' },
  { id: 'ro', name: 'Română' },
  { id: 'ru', name: 'Русский' },
  { id: 'sv', name: 'Svenska' },
  { id: 'tr', name: 'Türkçe' },
  { id: 'uk', name: 'Українська' },
  { id: 'ja', name: '日本語' },
  { id: 'ga', name: 'Gaeilge' },
];

const Menu = () => {
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const [textToFilter, setTextToFilter] = useState<string>('');
  const [options, setOptions] = useState<{ language: string; title: string }[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const canShowFlag = useRef<boolean>(i18next.isInitialized);
  const { t, i18n } = useTranslation();
  i18next.on('initialized', () => {
    canShowFlag.current = true;
  });
  const classes = useStyle();

  const onChangeLanguage = async (language: SupportedLanguage) => {
    const isLanguageLoaded = i18n.hasResourceBundle(language, 'translation');
    if (!isLanguageLoaded) {
      showGlobalSpinner();
      const translation = await getTranslation(language);
      i18n.addResourceBundle(language, 'translation', translation);
      hideGlobalSpinner();
    }
    i18n.changeLanguage(language);
    setIsOpenMenu(false);
    setTextToFilter('');
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpenMenu(false);
        setTextToFilter('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setOptions(
      Object.keys(menuList)
        .filter((key) =>
          menuList[key as keyof typeof menuList].title
            .toLocaleLowerCase()
            .includes(textToFilter.toLocaleLowerCase())
        )
        .map((key) => ({ title: menuList[key as keyof typeof menuList].title, language: key }))
    );
  }, [textToFilter]);

  const currentLanguage = i18n.language.toLowerCase() as keyof typeof menuList;
  dayjs.locale(currentLanguage.slice(0, 2));

  return (
    <div className="relative z-10 min-h-[39px] rounded-[8px] bg-white" ref={containerRef}>
      <LanguageFlag
        isOpenMenu={isOpenMenu}
        toggleMenu={() => setIsOpenMenu((prevState) => !prevState)}
      />
      {isOpenMenu && (
        <div className="fixed right-0 mt-[4px] min-w-[192px] max-w-[192px] rounded-[4px] border border-solid border-bg1 bg-bg4 py-[8px]">
          <div className="px-[16px] py-[8px]">
            <Search allGray placeholder={t('home.search')} onChange={setTextToFilter} />
          </div>
          <div className={`${classes.optionsContainer} max-h-[240px] overflow-y-auto`}>
            {options.map(({ language, title }) => (
              <div
                className="flex cursor-pointer justify-between px-[16px] py-[8px] hover:bg-bg3"
                onClick={() => onChangeLanguage(language)}
                key={`language-${language}`}
              >
                <p>{title}</p>
                {currentLanguage === language && (
                  <div className="[&>svg>path]:fill-success">
                    <CheckSolid />
                  </div>
                )}
              </div>
            ))}
            {options.length === 0 && <p className="text-center">{t('common.withoutResults')}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default Menu;
