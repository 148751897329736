import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getLanguageFromNavigator } from './utils';

i18n.use(initReactI18next).init({
  lng: getLanguageFromNavigator(),
  resources: {},
});

export default i18n;
