const colors = {
  primary: '#6B9CEB',
  primary10: '#C3FBF4',
  primary20: '#ACF3F8',
  primary30: '#95DDF4',
  primary40: '#80BFF0',
  primary60: '#4B49CC',
  primary70: '#573DB0',
  primary80: '#5D3195',
  primary90: '#5C2678',
  secondary: '#5672E6',
  secondary10: '#B5C8FB',
  secondary20: '#9CB2F8',
  secondary30: '#839DF3',
  secondary40: '#6C87ED',
  secondary60: '#3A7FA9',
  secondary70: '#2D7A89',
  secondary80: '#206864',
  secondary90: '#154739',
  tertiary: '#7B56E6',
  tertiary10: '#C3B5FB',
  tertiary20: '#AF9CF8',
  tertiary30: '#9C83F3',
  tertiary40: '#8B6CED',
  tertiary60: '#3A4DA9',
  tertiary70: '#2D5189',
  tertiary80: '#204C68',
  tertiary90: '#153E47',
};

const alertColors = {
  danger: '#DE1E1E',
  info: '#2B6CEE',
  success: '#37B24D',
  warning: '#DE7A16',
};

const backgrounds = {
  bg1: '#DBDBDB',
  bg2: '#E8EDED',
  bg3: '#F6F8F8',
  bg4: '#FFFFFF',
};

const texts = {
  text1: '#1A2021',
  text2: '#535859',
  text3: '#989B9B',
  text4: '#F6F8F8',
  text5: '#FFFFFF',
};

const interactive = {
  cerulean50: '#6B9CEB',
  cerulean40: '#80BFF0',
  amethyst40: '#8B6CED',
  amethyst30: '#9C83F3',
  majorelleBlue70: '#2D7A89',
  majorelleBlue80: '#206864',
};

const fonts = {
  IBMPlexSansBold: 'IBMPlexSans-Bold',
  IBMPlexSansBoldItalic: 'IBMPlexSans-BoldItalic',
  IBMPlexSansExtraLight: 'IBMPlexSans-ExtraLight',
  IBMPlexSansExtraLightItalic: 'IBMPlexSans-ExtraLightItalic',
  IBMPlexSansItalic: 'IBMPlexSans-Italic',
  IBMPlexSansLight: 'IBMPlexSans-Light',
  IBMPlexSansLightItalic: 'IBMPlexSans-LightItalic',
  IBMPlexSansMedium: 'IBMPlexSans-Medium',
  IBMPlexSansMediumItalic: 'IBMPlexSans-MediumItalic',
  IBMPlexSansRegular: 'IBMPlexSans-Regular',
  IBMPlexSansSemiBold: 'IBMPlexSans-SemiBold',
  IBMPlexSansThin: 'IBMPlexSans-Thin',
  IBMPlexSansThinItalic: 'IBMPlexSans-ThinItalic',
};

const boxShadow = {
  100: '0px 4px 8px -4px rgba(23, 23, 23, 0.25)',
  200: '0px 8px 12px -4px rgba(23, 23, 23, 0.25)',
  300: '0px 8px 12px -4px rgba(23, 23, 23, 0.25)',
  down: '0px -1px 0px 0px #E8EDED inset',
  up: '0px 1px 0px 0px #E8EDED inset',
  right: '-1px 0px 0px 0px #E8EDED inset',
  left: '1px 0px 0px 0px #E8EDED inset',
};

export default colors;

export { alertColors, backgrounds, boxShadow, texts, interactive, fonts, colors };
