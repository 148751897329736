import simpleLogo from '../../assets/images/simpleLogo.svg';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const Loader = () => {
  return (
    <Spin
      indicator={
        <div className="relative text-[46px]">
          <Loading3QuartersOutlined style={{ color: '#177DB7' }} spin />
          <img className="absolute left-[11px] top-[22px] w-[25px]" src={simpleLogo} alt="" />
        </div>
      }
    />
  );
};

export default Loader;
