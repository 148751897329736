import { Button, Drawer, Modal } from 'antd';
import { XmarkSolid } from '../../../utils/icons';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import staticImage1 from '../../../assets/images/staticImage1.jpg';
import animatedImage1 from '../../../assets/videos/animatedImage1.mp4';
import staticImage2 from '../../../assets/images/staticImage2.jpg';
import animatedImage2 from '../../../assets/videos/animatedImage2.mp4';
import { useIsMobile } from '../../../utils/hooks';
import { useMemorial } from '../../../context/memorial.provider';
const images = [
  {
    static: staticImage1,
    animated: animatedImage1,
  },
  {
    static: staticImage2,
    animated: animatedImage2,
  },
];

const getImagenToShow = () => {
  const min = 0;
  const max = 1;
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const ModalWithPreview = ({
  onClose,
  onSubmit,
  showSubmit,
}: {
  onClose: () => void;
  onSubmit?: () => void;
  showSubmit?: boolean;
}) => {
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [imageToShow] = useState<number>(getImagenToShow());

  const { products } = useMemorial();
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  const getContent = () => (
    <>
      {!showConfirmation && (
        <>
          <div className="flex flex-col justify-between md:flex-row" key={imageToShow}>
            <div className="flex flex-col items-center">
              <p className="font-bold">{t('newMemorial.convertToGift.staticImage')}</p>
              <img
                src={images[imageToShow].static}
                alt=""
                className="mt-[16px] max-h-[250px] min-w-[250px] rounded-[4px]"
              />
            </div>

            <div className="mt-[40px] flex flex-col items-center md:mt-[0px]">
              <p className="font-bold">{t('newMemorial.convertToGift.animatedImage')}</p>
              <video className="mt-[16px] w-[100%] max-w-[250px] rounded-[4px]" autoPlay loop>
                <source type="video/mp4" src={images[imageToShow].animated} />
              </video>
            </div>
          </div>
          <div className="mt-[57px] flex flex-col md:flex-row md:justify-end">
            <Button className="mb-[8px] md:mb-[0px] md:mr-[8px]" onClick={onClose}>
              {t('newMemorial.convertToGift.back')}
            </Button>
            {showSubmit && (
              <Button
                type="primary"
                onClick={() => {
                  setShowConfirmation(true);
                }}
              >
                {t('newMemorial.convertToGift.animateImage')}
              </Button>
            )}
          </div>
        </>
      )}

      {showConfirmation && (
        <>
          <p>{t('newMemorial.convertToGift.confirmationText1')}</p>
          <p className="my-[24px]">
            {t('newMemorial.convertToGift.costInfo1', {
              currency: products.animateImage.currency.toUpperCase(),
            })}
          </p>
          <div className="mt-[57px] flex flex-col md:flex-row md:justify-end">
            <Button className="mb-[8px] md:mb-[0px] md:mr-[8px]" onClick={onClose}>
              {t('common.cancel')}
            </Button>
            <Button type="primary" onClick={onSubmit}>
              {t('newMemorial.convertToGift.confirmationButton')}
            </Button>
          </div>
        </>
      )}
    </>
  );

  return (
    <>
      {isMobile && (
        <Drawer
          title={
            showConfirmation
              ? t('newMemorial.convertToGift.confirmationTitle')
              : t('newMemorial.convertToGift.modalTitle')
          }
          placement="bottom"
          onClose={onClose}
          open
          closeIcon={<XmarkSolid />}
          rootClassName="drawerMobile"
          size="large"
        >
          {getContent()}
        </Drawer>
      )}
      {!isMobile && (
        <Modal
          open
          title={
            showConfirmation
              ? t('newMemorial.convertToGift.confirmationTitle')
              : t('newMemorial.convertToGift.modalTitle')
          }
          footer={null}
          onCancel={onClose}
          closeIcon={<XmarkSolid />}
          centered
          width={620}
        >
          {getContent()}
        </Modal>
      )}
    </>
  );
};

export default ModalWithPreview;
