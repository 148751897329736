import { Global, css } from '@emotion/react';
import { alertColors, backgrounds, boxShadow, fonts, interactive, texts } from '../../utils/theme';

const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        body {
          top: 0 !important;
          color: ${texts.text1};
          * {
            outline: none;
          }
          img {
            -webkit-user-drag: none;
            user-drag: none;
          }
        }
        h1 {
          color: ${texts.text1};
          font-family: ${fonts.IBMPlexSansBold};
          font-size: 31.25px;
          line-height: 36px;
        }

        h2 {
          color: ${texts.text1};
          font-family: ${fonts.IBMPlexSansBold};
          font-size: 25px;
          font-weight: 28px;
          &.light {
            font-family: ${fonts.IBMPlexSansRegular};
          }
        }

        h3 {
          color: ${texts.text1};
          font-family: ${fonts.IBMPlexSansBold};
          font-size: 20px;
          line-height: 28px;
        }

        h4 {
          color: ${texts.text1};
          font-family: ${fonts.IBMPlexSansRegular};
          font-size: 16px;
          line-height: 24px;
        }

        h5 {
          color: ${texts.text1};
          font-family: ${fonts.IBMPlexSansBold};
          font-size: 12.8px;
          line-height: 20px;
        }

        p {
          color: ${texts.text1};
          font-family: ${fonts.IBMPlexSansRegular};
          font-size: 16px;
          line-height: 24px;
          margin: 0;

          &.smallParagraph {
            font-family: ${fonts.IBMPlexSansRegular};
            font-size: 12.8px;
            line-height: 20px;
          }

          &.smallParagraphBold {
            font-family: ${fonts.IBMPlexSansSemiBold};
            font-size: 12.8px;
            line-height: 20px;
          }

          &.paragraphBold {
            font-family: ${fonts.IBMPlexSansBold};
          }

          &.link {
            text-decoration-line: underline;
            cursor: pointer;
          }
        }

        .ant-pagination {
          .ant-pagination-item-active {
            font-weight: normal !important;
            background-color: unset !important;
          }

          .ant-pagination-item,
          .ant-pagination-prev,
          .ant-pagination-next,
          .ant-pagination-jump-next,
          .ant-pagination-jump-prev {
            margin-inline-end: 4px !important;
            min-width: 40px;
            height: 40px;
            border-radius: 8px;
            border: 1px solid ${backgrounds.bg1} !important;
          }
        }

        .ant-btn {
          font-family: ${fonts.IBMPlexSansMedium};
          border-radius: 8px;
          height: 40px;
          min-width: 40px;
          padding-left: 16px;
          padding-right: 16px;

          &.ant-btn-default:not(:disabled) {
            border: 1px solid ${backgrounds.bg1};

            &:hover {
              background-color: ${backgrounds.bg2};
              border-color: ${backgrounds.bg2};
              color: ${texts.text1};
            }
          }
          &.ant-btn-background-ghost {
            height: 24px;
            padding-top: 0;
            padding-bottom: 0;
            border: none !important;
            &.icon {
              padding: 8px;
              height: 40px;
              &:hover {
                background-color: ${backgrounds.bg2};
              }
            }
          }
          &:disabled {
            color: ${texts.text3};
            background-color: ${backgrounds.bg3};
            & > svg > path {
              fill: ${texts.text3};
            }
          }

          &.secondary {
            background-color: ${backgrounds.bg4};
            border-color: ${backgrounds.bg1};
            color: ${texts.text1};

            &:hover {
              background-color: ${backgrounds.bg2};
              border-color: ${backgrounds.bg2};
            }
          }
          &.primaryDisabled {
            color: ${texts.text3};
            background-color: ${backgrounds.bg3};
            border-color: ${backgrounds.bg1};
            box-shadow: unset;
            &:hover {
              background-color: ${backgrounds.bg3} !important;
              color: ${texts.text3} !important;
            }
          }
          &.small {
            height: 32px;
            padding: 4px 12px;
            font-size: 12.8px;
            line-height: 20px;
          }
        }

        .ant-input-search {
          .ant-input-affix-wrapper {
            border-color: transparent;
          }
          .ant-input-group-addon {
            background: #fff;
            button {
              border-color: transparent;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        .ant-checkbox-wrapper {
          display: flex;
          align-items: start;
          color: ${texts.text1};
          &:after {
            display: flex;
            align-items: center;
          }
          &.ant-checkbox-wrapper-checked {
            &:hover {
              .ant-checkbox.ant-checkbox-checked:not(.ant-checkbox-disabled) {
                .ant-checkbox-inner {
                  background: ${alertColors.success};
                }
                &:after {
                  border-color: ${alertColors.success};
                }
              }
            }
          }
          .ant-checkbox {
            top: 0;
            transform: inherit;
            input {
              margin: 0;
            }
            &.ant-checkbox-checked:not(.ant-checkbox-disabled):hover {
              .ant-checkbox-inner {
                background: ${alertColors.success};
              }
            }
            .ant-checkbox-inner {
              width: 24px;
              height: 24px;
              &:after {
                width: 9px;
                height: 14px;
                border-width: 3px;
              }
            }
          }
          & > span:first-of-type {
            position: absolute;
          }
          & > span:last-of-type {
            margin-left: 24px;
            width: 100%;
          }
        }

        .ant-radio-wrapper {
          display: flex;
          align-items: center;
          color: ${texts.text1};
          .ant-radio {
            inset-block-start: 0px;
            input {
              margin: 0;
            }
            .ant-radio-inner {
              width: 24px;
              height: 24px;
            }
          }
          .ant-radio-checked {
            .ant-radio-inner {
              &::after {
                width: 36px;
                height: 36px;
                margin-block-start: -18px;
                margin-inline-start: -18px;
              }
            }
          }
        }

        .ant-modal-root {
          .ant-modal-mask {
            background-color: rgba(0, 0, 0, 0.8);
          }
          .ant-modal-wrap {
            .ant-modal {
              .ant-modal-content {
                padding: 0;
                border: 1px solid ${backgrounds.bg1};
                .ant-modal-close-x {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  svg {
                    color: ${texts.text1};
                    width: 10px;
                    height: 10px;
                  }
                }
                .ant-modal-header {
                  padding: 16px 24px;
                  margin-bottom: 0;
                  border-bottom: 1px solid ${backgrounds.bg1};
                  .ant-modal-title {
                    font-family: ${fonts.IBMPlexSansBold};
                    font-size: 20px;
                    color: ${texts.text1};
                    line-height: 28px;
                  }
                }
                .ant-modal-body {
                  padding: 24px;
                  font-size: 16px;
                  line-height: 24px;
                  @media (max-width: 767px) {
                    padding: 16px;
                  }
                }

                .ant-modal-footer {
                  margin-top: 0;
                  padding: 0 24px 24px 24px;

                  @media (max-width: 767px) {
                    display: flex;
                    flex-direction: column-reverse;
                    button {
                      width: 100%;
                      margin: 0;

                      &:last-child {
                        margin-bottom: 8px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .ant-card {
          border-color: ${backgrounds.bg1};
          box-shadow: inset 0px -1px 0px ${backgrounds.bg2};
          .ant-card-head {
            border-color: ${backgrounds.bg1};
            .ant-card-head-title {
              color: ${texts.text1};
              font-family: ${fonts.IBMPlexSansBold};
              font-size: 20px;
              line-height: 28px;
            }
          }
          .ant-card-body {
            color: ${texts.text1};
            font-family: ${fonts.IBMPlexSansRegular};
            font-size: 16px;
            line-height: 24px;
          }
        }

        .ant-notification {
          & .ant-notification-notice-wrapper {
            border-radius: 4px;
          }
          & .ant-notification-notice {
            padding: 0px !important;
            border-radius: 4px;
            box-shadow: ${boxShadow[100]};
            border: 1px solid ${backgrounds.bg1};
            & .ant-notification-notice-message {
              margin-bottom: 0px !important;
              margin-inline-start: 0px !important;
              padding: 14px 0px;
              padding-left: 14px;
              padding-inline-end: 38px !important;
              @media (max-width: 768px) {
                padding-left: 6px;
                padding: 8px;
                padding-inline-end: 12px !important;
              }
            }
          }
          & .ant-notification-notice-close {
            top: 16px !important;
            right: 16px !important;
          }
          & .ant-notification-notice-success {
            border-left: 4px solid ${alertColors.success};
          }
          & .ant-notification-notice-info {
            border-left: 4px solid ${alertColors.info};
          }
          & .ant-notification-notice-warning {
            border-left: 4px solid ${alertColors.warning};
          }
          & .ant-notification-notice-error {
            border-left: 4px solid ${alertColors.danger};
          }
        }

        .ant-message {
          top: 87px;
          .ant-message-notice {
            border-radius: 4px;
            .ant-message-notice-content {
              display: flex;
              align-items: center;
              margin: auto;
              min-height: 53px;
              text-align: left;
              border: 1px solid ${backgrounds.bg1};
              border-radius: 4px;
              float: right;
              position: relative;
              right: 8px;
              .anticon {
                margin-right: 10px;
                svg {
                  width: 20px;
                  height: 20px;
                }
              }
              .ant-message-custom-content {
                display: flex;
                align-items: center;
                span {
                  &:last-of-type {
                    font-family: ${fonts.IBMPlexSansRegular};
                    font-size: 16px;
                    line-height: 24px;
                    color: ${texts.text1};
                  }
                }
              }
              @media (min-width: 768px) {
                width: 100%;
                max-width: 652px;
              }
            }
            &.ant-message-notice-success {
              .ant-message-notice-content {
                border-left: 4px solid ${alertColors.success};
              }
            }
            &.ant-message-notice-info {
              .ant-message-notice-content {
                border-left: 4px solid ${alertColors.info};
              }
            }
            &.ant-message-notice-warning {
              .ant-message-notice-content {
                border-left: 4px solid ${alertColors.warning};
              }
            }
            &.ant-message-notice-error {
              .ant-message-notice-content {
                border-left: 4px solid ${alertColors.danger};
              }
            }
          }
        }

        .ant-steps {
          &.ant-steps-horizontal {
            .ant-steps-item-content {
              margin-top: 8px;
              .ant-steps-item-title {
                font-family: ${fonts.IBMPlexSansBold};
                font-size: 16px;
                line-height: 24px;

                @media (max-width: 767px) {
                  font-size: 12.8px;
                  line-height: 16px;
                }
              }
            }
          }

          .ant-steps-item {
            &.ant-steps-item-finish {
              .ant-steps-item-container {
                .ant-steps-item-icon {
                  background: ${alertColors.success};
                  border-color: ${alertColors.success};
                  svg {
                    color: #fff;
                  }
                  &:hover {
                    background: ${interactive.majorelleBlue80};
                    border-color: ${interactive.majorelleBlue80} !important;
                  }
                }
                .ant-steps-item-content {
                  .ant-steps-item-title {
                    color: ${texts.text2} !important;
                  }
                }
                .ant-steps-item-tail {
                  &:after {
                    background: ${alertColors.success};
                  }
                }
              }
            }

            &.ant-steps-item-active {
              .ant-steps-item-container {
                .ant-steps-item-icon {
                  background: ${interactive.cerulean50};
                  border-color: ${interactive.cerulean50};
                  svg {
                    color: #fff;
                  }
                }
                .ant-steps-item-content {
                  .ant-steps-item-title {
                    color: ${texts.text1};
                  }
                }
                .ant-steps-item-tail {
                  padding: 0 16px;
                  &:after {
                    background: ${backgrounds.bg1};
                  }
                }
              }
            }

            &.ant-steps-item-wait {
              .ant-steps-item-container {
                .ant-steps-item-icon {
                  background: ${backgrounds.bg1};
                  border-color: ${backgrounds.bg1};
                  span {
                    color: ${texts.text3};
                  }
                }
              }
            }
            .ant-steps-item-container {
              .ant-steps-item-icon {
                font-family: ${fonts.IBMPlexSansBold};
                font-size: 16px;
                @media (max-width: 767px) {
                  margin-inline-start: 8px;
                  margin: 0 10px;
                }
              }
              .ant-steps-item-tail {
                padding: 0 15px;
                top: 14px;
                &:after {
                  height: 4px;
                }

                @media (max-width: 767px) {
                  margin-left: 25px;
                }
              }

              .ant-steps-item-content {
                @media (max-width: 767px) {
                  max-width: 50px;
                }
              }
            }
          }
        }

        .ant-layout-header {
          height: 80px;
          font-family: ${fonts.IBMPlexSansSemiBold};
          box-shadow: inset 0px -1px 0px #e8eded;
          ul {
            background-color: #fff;
            height: 100%;

            li {
              display: flex;
              align-items: center;
            }
          }
        }
        .ant-form-item {
          .ant-form-item-row {
            flex-direction: column;
            align-items: flex-start;

            .ant-form-item-label {
              line-height: 1;
              height: initial;
              label {
                font-size: 12.8px;
                font-family: ${fonts.IBMPlexSansSemiBold};
                margin-bottom: 4px;
                line-height: 20px;
                height: 20px;
                color: ${texts.text2};

                &.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
                  &::after {
                    display: inline-block;
                    margin-inline-end: 4px;
                    color: ${alertColors.danger};
                    font-size: 16px;
                    font-family: SimSun, sans-serif;
                    line-height: 1;
                    content: '*';
                  }
                  &::before {
                    content: '';
                  }
                }
                &:not(.ant-form-item-required) {
                  &::after {
                    content: '';
                  }
                }
              }
              @media (max-width: 767px) {
                padding: 0px;
              }
            }
          }

          .ant-form-item-control {
            width: 100%;
            min-height: max-content;
            .ant-form-item-control-input,
            .ant-input-number,
            .ant-picker,
            .ant-form-item-control-input-content,
            .ant-input-affix-wrapper {
              min-height: 40px;
              width: 100%;
              border-radius: 4px;
              // height: 40px;
            }

            .ant-input-number input {
              height: 40px;
            }

            .ant-input-affix-wrapper-disabled {
              background: ${backgrounds.bg3} !important;
            }
          }

          .ant-form-item-explain-error {
            font-size: 12.8px;
          }
        }
        .ant-tabs-nav {
          margin-bottom: 24px !important;
          .ant-tabs-nav-wrap {
            .ant-tabs-nav-list {
              .ant-tabs-tab {
                padding: 0 0 8px 0;
                .ant-tabs-tab-btn {
                  font-size: 16px;
                  font-family: ${fonts.IBMPlexSansSemiBold};
                }
              }
            }
          }
          @media (max-width: 767px) {
            margin-bottom: 16px !important;
          }
        }

        .ant-carousel {
          .slick-dots {
            li {
              button {
                width: 16px;
                height: 16px;
                border-radius: 50px;
                background: ${backgrounds.bg2};
              }

              &.slick-active {
                width: 16px;
                button {
                  background: ${interactive.cerulean50};
                }
              }
            }
          }

          .slick-prev {
            left: -32px;
          }

          .slick-next {
            right: -32px;
          }

          .slick-dots-bottom {
            bottom: -36px;
          }
        }

        .ant-switch {
          min-width: 62px;
          width: 62px;
          height: 32px;
          background: ${backgrounds.bg2};
          &:hover {
            background: ${backgrounds.bg2};
          }
          .ant-switch-handle {
            width: 24px;
            height: 24px;
            top: 4px;
            left: 4px;
            &:before {
              border-radius: 100px;
            }
          }
          &.ant-switch-checked {
            background: ${alertColors.success};
            &:hover {
              background: ${alertColors.success} !important;
            }
            .ant-switch-handle {
              left: 34px;
            }
          }
        }

        .ant-alert {
          padding: 8px 16px;
          border-radius: 4px;
          border: none;
          background-color: white;
          border: 1px solid ${backgrounds.bg1};
          &.ant-alert-info {
            border-left: 4px solid ${alertColors.info};
            align-items: unset;
          }

          &.ant-alert-warning {
            border-left: 4px solid ${alertColors.warning};
          }

          &.ant-alert-warning {
            border-left: 4px solid ${alertColors.danger};
          }

          &.ant-alert-success {
            border-left: 4px solid ${alertColors.success};
          }
          & .ant-alert-close-icon {
            display: flex;
            height: fit-content;
            & svg {
              & path {
                fill: ${texts.text2};
              }
            }
          }
        }

        .ant-pagination {
          width: 100%;

          .ant-pagination-options {
            float: right;
          }
        }
        .ant-progress {
          margin-bottom: 0;
          line-height: 1;
          .ant-progress-inner {
            background-color: ${backgrounds.bg1};
            border-radius: 4px;
            .ant-progress-bg {
              background-color: ${alertColors.success};
              border-radius: 4px;
              height: 4px !important;
            }
          }
        }
        .footerButtons {
          @media (max-width: 767px) {
            padding: 16px !important;
            position: fixed !important;
            bottom: 0;
            left: 0;
            width: 100vw;
            display: flex;
            flex-direction: column-reverse !important;
            background-color: ${backgrounds.bg4};
            border-top: 1px solid ${backgrounds.bg1};
          }
        }
        a {
          text-decoration-line: underline;
          font-family: ${fonts.IBMPlexSansRegular};
          font-size: 16px;
          line-height: 24px;
          color: #2b6cee;
        }
        .dropdown-menu {
          & .ant-dropdown-menu {
            padding: 8px 0px;
            border: 1px solid ${backgrounds.bg1};
            box-shadow: none;
            & .ant-dropdown-menu-item-group {
              & .ant-dropdown-menu-item-group-title {
                padding: 12px 16px;
                & p {
                  color: ${texts.text2};
                }
              }
            }
            & .ant-dropdown-menu-item {
              padding: 8px 16px;
            }
          }
        }
        .menu-mobile {
          height: calc(100vh - 39px);
          top: 39px !important;
          left: 0px !important;
          box-shadow: ${boxShadow[100]};
          & .ant-dropdown-menu {
            height: 100%;
            border: none;
            border-radius: 0px;
          }
        }
        .ant-drawer {
          .ant-drawer-mask {
            background-color: rgba(0, 0, 0, 0.8);
          }
        }
        .drawerMobile {
          & .ant-drawer-content-wrapper {
            // height: fit-content !important;
            max-height: calc(100% - 156px) !important;
            overflow: auto;
            width: 100%;
            & .ant-drawer-content {
              border-radius: 8px 8px 0px 0px;
              & .ant-drawer-header {
                padding: 16px;
                & .ant-drawer-header-title {
                  display: flex;
                  flex-direction: row-reverse;
                  & .ant-drawer-close {
                    margin-right: 0px;
                    margin-left: 8px;
                  }
                }
              }
              & .ant-drawer-body {
                padding: 24px 16px;
              }
            }
          }
        }
        .drawerWithBigHeight {
          & .ant-drawer-content-wrapper {
            height: calc(100% - 50px) !important;
            max-height: unset !important;
          }
          & .ant-drawer-body {
            padding: 0px !important;
          }
        }
        .ant-btn-link {
          font-family: ${fonts.IBMPlexSansRegular};
          font-size: 16px;
          line-height: 24px;
          color: ${texts.text1};
          padding: 0px;
          text-decoration-line: underline;
          & ::hover {
            color: #2b6cee;
          }
        }
        .ant-select {
          &.ant-select-disabled {
            & .ant-select-selector {
              background: ${backgrounds.bg3} !important;
            }
          }
        }
        .ant-input-disabled {
          background: ${backgrounds.bg3} !important;
        }
        .ant-tag {
          margin-inline-end: 0px;
          padding-inline: 0px;
          padding: 4px 8px;
          border: unset;
          font-size: 12.8px;
          line-height: 20px;
          font-weight: 600;
        }
        .check-input {
          .ant-form-item-control-input,
          .ant-form-item-control-input-content {
            min-height: unset !important;
          }
        }
        .ant-popover {
          & .ant-popover-arrow {
            &::before {
              background: ${texts.text1};
            }
          }
          & .ant-popover-content {
            & .ant-popover-inner {
              padding: 4px 8px;
              border-radius: 4px;
              background-color: ${texts.text1};
              & .ant-popover-inner-content {
                color: ${texts.text5};
              }
            }
          }
          &.whitePopover {
            & .ant-popover-arrow {
              &::before {
                background: ${backgrounds.bg4};
              }
            }
            & .ant-popover-content {
              & .ant-popover-inner {
                padding: 16px;
                background-color: ${backgrounds.bg4};
              }
            }
          }
        }
        .ant-tooltip {
          & .ant-tooltip-arrow {
            &::before {
              background: ${texts.text1};
            }
          }
          & .ant-tooltip-content {
            & .ant-tooltip-inner {
              padding: 4px 8px;
              border-radius: 4px;
              background-color: ${texts.text1};
              color: ${texts.text5};
            }
          }
        }
        .ant-avatar {
          &.ant-avatar-square {
            border-radius: 4px;
          }
        }
        .grecaptcha-badge {
          visibility: hidden;
        }
        .ant-breadcrumb {
          .ant-breadcrumb-link {
            color: ${texts.text1};
            cursor: pointer;
          }
          & ol {
            & li:last-of-type > span {
              font-family: ${fonts.IBMPlexSansBold};
              cursor: default;
            }
          }
        }
        .gt-cookie-dialog__modal {
          font-size: 16px;
          border-radius: 8px;
          font-family: ${fonts.IBMPlexSansRegular};
        }
        .gt-cookie-widget {
          font-size: 16px;
          padding: 16px;
          border-radius: 8px;
          font-family: ${fonts.IBMPlexSansRegular};
          @media (max-width: 767px) {
            padding: 8px;
          }
        }
        .gt-cookie-dialog__button,
        .gt-cookie-widget__button {
          &:before {
            display: none;
          }
          border-radius: 8px;
          height: 40px;
          max-height: 40px;
          padding: 3px 16px;
          &:hover,
          &:focus {
            transform: unset;
            opacity: 1;
          }
        }
        .gt-cookie-dialog__button {
          &:not(.gt-cookie-dialog__button--stroke) {
            background-color: ${interactive.cerulean50};
          }
          &.gt-cookie-dialog__button--stroke {
            border: 1px solid ${backgrounds.bg1};
            @media (min-width: 768px) {
              margin-right: 8px;
            }
          }
        }
        .gt-cookie-widget__button {
          &:not(.gt-cookie-widget__button--stroke) {
            background-color: ${interactive.cerulean50};
          }

          &.gt-cookie-widget__button--stroke {
            border: 1px solid ${backgrounds.bg1};
            margin-right: 8px;
          }
        }
        .gt-cookie-dialog__button .gt-cookie-dialog__button--stroke .gt-cookie-dialog__checkbox {
          display: flex;
          align-items: center;
        }
        .gt-cookie-dialog__checkbox-display {
          width: 24px;
          height: 24px;
          border-radius: 4px;
          background-color: white;
          border-color: #d9d9d9;
          opacity: 1;
          &:hover {
            border-color: ${alertColors.success};
          }
        }
        .gt-cookie-dialog__checkbox-input {
          &:checked + .gt-cookie-dialog__checkbox-display {
            background-color: ${alertColors.success};
            border-color: ${alertColors.success};
          }
        }
        .gt-cookie-dialog__checkbox-label {
          line-height: 26px;
        }
        .gt-cookie-dialog__checkbox-display > svg {
          width: 16px;
          height: 16px;
        }
        .gt-cookie-dialog__footer,
        .gt-cookie-widget--pill .gt-cookie-widget__actions {
          @media (min-width: 768px) {
            gap: 0;
          }
        }
        .gt-cookie-noscroll {
          overflow: initial;
        }
      `}
    />
  );
};

export default GlobalStyles;
