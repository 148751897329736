import { notification } from 'antd';
import { useEffect, useState } from 'react';
import {
  CircleError,
  CircleExclamationSolid,
  CircleSuccess,
  TriangleExclamationSolid,
  XmarkSolid,
} from '../icons';
import { useMemorial } from '../../context/memorial.provider';

const getIsMobile = () => window.innerWidth < 768;

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(getIsMobile());

  useEffect(() => {
    const onResize = () => {
      setIsMobile(getIsMobile());
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return isMobile;
};

export const notificationMessage = ({
  message,
  type,
}: {
  message: string;
  type: 'success' | 'error' | 'info' | 'warning';
}) => {
  const isMobile = getIsMobile();
  const commonIconStyle = 'flex relative top-[1px] mr-[8px] [&>svg]:w-[20px] [&>svg]:h-[20px]';
  let icon;

  if (type === 'success') {
    icon = (
      <div className={`${commonIconStyle} [&>svg>path]:fill-success`}>
        <CircleSuccess />
      </div>
    );
  } else if (type === 'error') {
    icon = (
      <div className={`${commonIconStyle} [&>svg>path]:fill-danger`}>
        <CircleError />
      </div>
    );
  } else if (type === 'info') {
    icon = (
      <div className={`${commonIconStyle} [&>svg>path]:fill-info`}>
        <CircleExclamationSolid />
      </div>
    );
  } else {
    icon = (
      <div className={`${commonIconStyle} [&>svg>path]:fill-warning`}>
        <TriangleExclamationSolid />
      </div>
    );
  }

  notification[type]({
    message: (
      <div className="flex">
        {icon}
        <p>{message}</p>
      </div>
    ),
    placement: isMobile ? 'top' : 'topRight',
    closeIcon: isMobile ? (
      false
    ) : (
      <div className="[&>svg>path]:fill-text2">
        <XmarkSolid />
      </div>
    ),
    icon: <></>,
  });
};

export const useCost = () => {
  const [cost, setCost] = useState<{ 1: string; 2: string; 3: string; 4: string }>({
    1: '',
    2: '',
    3: '',
    4: '',
  });
  const { products } = useMemorial();

  useEffect(() => {
    if (products) {
      setCost({
        1: `295 ${products.premium.currency.toUpperCase()}`,
        2: `50 ${products.basic.currency.toUpperCase()}`,
        3: `20 ${products.animateImage.currency.toUpperCase()}`,
        4: `70 ${products.basic.currency.toUpperCase()}`,
      });
    }
  }, [products]);

  return cost;
};
