import axios, { AxiosInstance } from 'axios';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useMemorial } from '../context/memorial.provider';
import { useNavigate } from 'react-router-dom';
import { logout } from '../context/memorial.actions';
import { notificationMessage } from './hooks';

const getOptions = (contentType: string, token: string, language: string) => ({
  baseURL: `${process.env.REACT_APP_API_URL}${language ? `/${language}` : ''}`,
  headers: {
    'Content-Type': contentType,
    'Access-Control-Allow-Origin': '*',
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
  },
});
const useApi = () => {
  const { t, i18n } = useTranslation();
  const { memorialDispatch } = useMemorial();
  const navigate = useNavigate();
  const api = useCallback(
    (contentType = 'application/json', withToken = true, temporaryToken = '') => {
      const token = localStorage.getItem('memorial_token') ?? '';
      const [language, additionalInfo] = i18n.language.split('_');
      const api: AxiosInstance = axios.create(
        getOptions(
          contentType,
          temporaryToken || (withToken ? token : ''),
          language + (additionalInfo ? `-${additionalInfo.toUpperCase()}` : '')
        )
      );

      api.interceptors.request.use((config) => {
        return config;
      });
      api.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          // When error is 499 not show message because the user is not registered in Stripe and the login handle this error
          if (error?.response?.status !== 499) {
            if (error?.response?.data?.msg) {
              if (
                error.response.status === 498 &&
                window.location.href.includes('/home/memorials/new')
              ) {
                // when the user is creating his account, it is checked in an interval if the user has verified his account in his email,
                // if he has not done it, you do not want to show error.
                return '';
              } else {
                notificationMessage({ message: error.response.data.msg, type: 'error' });
              }
            } else {
              switch (error?.response?.status) {
                case 500: {
                  notificationMessage({ message: t('api.error500'), type: 'error' });
                  break;
                }
                case 404: {
                  notificationMessage({ message: t('api.error404'), type: 'error' });
                  break;
                }
                case 401: {
                  notificationMessage({ message: t('api.error401'), type: 'error' });
                  localStorage.removeItem('memorial_token');
                  memorialDispatch(logout());
                  navigate('/');
                  break;
                }
                case undefined: {
                  notificationMessage({ message: t('api.noServerConnection'), type: 'error' });
                  break;
                }
                default:
                  break;
              }
            }
          }

          return Promise.reject(error);
        }
      );

      return api;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { api };
};

export default useApi;
