import { Input, InputRef } from 'antd';
import { MagnifyingGlassSolid, XmarkSolid } from '../../utils/icons';
import { useState, forwardRef } from 'react';

const Search = forwardRef<
  InputRef,
  {
    allGray?: boolean;
    disabled?: boolean;
    onChange?: (value: string) => void;
    placeholder?: string;
  }
>(({ allGray, disabled, onChange, placeholder }, ref) => {
  const [hasFocus, setHasFocus] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  return (
    <Input
      ref={ref}
      disabled={disabled}
      prefix={
        <div
          className={`relative top-[2px] mr-[4px] ${
            !hasFocus && !value ? '[&>svg>path]:fill-text2' : ''
          }`}
        >
          <MagnifyingGlassSolid />
        </div>
      }
      suffix={
        hasFocus || value ? (
          <div
            className="ml-[4px] cursor-pointer [&>svg>path]:fill-text2"
            onClick={() => {
              setValue('');
              onChange?.('');
            }}
          >
            <XmarkSolid />
          </div>
        ) : undefined
      }
      placeholder={placeholder}
      onFocus={() => setHasFocus(true)}
      onBlur={() => setHasFocus(false)}
      value={value}
      onChange={(event) => {
        setValue(event.target.value);
        onChange?.(event.target.value);
      }}
      className={`max-h-[40px] ${allGray ? 'border-bg3 bg-bg3 [&>input]:bg-bg3' : ''}`}
    />
  );
});

export default Search;
