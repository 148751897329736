import type { RcFile } from 'antd/es/upload/interface';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { SupportedLanguage } from '../types/SupportedLanguage';
import { TCountryCode, countries } from 'countries-list';
import { getEmojiFlag } from 'countries-list';

dayjs.extend(customParseFormat);

type Key = string | number | symbol;
type Path = Key[] | Key;

type GetRecursive<T, K extends Path> = K extends [infer Head, ...infer Tail]
  ? Head extends Key
    ? Tail extends Path
      ? Head extends keyof T
        ? T[Head] extends object
          ? GetRecursive<T[Head], Tail>
          : T[Head] | undefined
        : undefined
      : never
    : undefined
  : T;

function get<T extends object, K extends Path>(
  object: T | null | undefined,
  path: K,
  defaultValue?: any
): K extends [infer Head, ...infer Tail]
  ? Head extends Key
    ? Tail extends Path
      ? Head extends keyof T
        ? T[Head] extends object
          ? GetRecursive<T[Head], Tail>
          : T[Head] | undefined
        : any
      : never
    : any
  : K extends keyof T
    ? T[K] extends object
      ? GetRecursive<T[K], []>
      : T[K] | undefined
    : any {
  if (object == null) {
    return defaultValue;
  }

  const pathArray = Array.isArray(path) ? path : [path];

  let value: any = object;
  for (const key of pathArray) {
    if (value && typeof key === 'string' && key in value) {
      value = value[key];
    } else if (value && typeof key === 'number' && Array.isArray(value)) {
      value = value[key];
    } else {
      return defaultValue;
    }
  }
  return value;
}

const getBase64 = (img: RcFile | null) =>
  new Promise((resolve, reject) => {
    if (!img) {
      resolve('');
    } else {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result as string));
      reader.readAsDataURL(img);
    }
  });

const passwordRegex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?!.*\s)[0-9a-zA-Z\W]{8,}$/;

export const base64ToFile = (base64String: string, filename: string, mimeType: string) => {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: mimeType });
  return new File([blob], filename, { type: mimeType });
};

export const nameRegex = /^[\p{L} ]+$/u;
export const isValidName = (name: string) => nameRegex.test(name) && name.trim().length !== 0;
export const getTrimmedString = (str: string | undefined) =>
  (str || '').replace(/\s+/g, ' ').trim();

export const getFormattedDateToShow = (date?: string | Dayjs) =>
  date
    ? typeof date === 'string'
      ? dayjs(date.split('T')[0]).format('MMMM D, YYYY')
      : date.format('MMMM D, YYYY')
    : '';

export const downloadBase64 = (file: string, fileName: string) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = file;
  downloadLink.download = fileName;
  downloadLink.click();
};

export { get, getBase64, passwordRegex };

export const supportedLanguages = [
  // 'ar',
  'bg',
  'ca',
  'cs',
  'cy',
  'da',
  'de',
  'el',
  'en_gb',
  'en_us',
  'es_es',
  'es_va',
  'eu',
  'fi',
  'fr',
  'ga',
  'gl',
  'hu',
  'it',
  'ja',
  'ko',
  'nl',
  'no',
  'pl',
  'pt_br',
  'pt_pt',
  'ro',
  'ru',
  'sv',
  'tr',
  'uk',
  'zh',
];

/**
 * Get language from navigator, if the language is not supported
 * return 'es_es' (Spanish)
 *
 * @return {string}
 */
export const getLanguageFromNavigator = (): SupportedLanguage => {
  const language = navigator.language;

  if (supportedLanguages.includes(language)) {
    return language;
  } else if (navigator.language.slice(0, 2) === 'es') {
    return 'es_es';
  } else {
    return 'en_us';
  }
};

export const showGlobalSpinner = () => {
  const initialLoader = document.getElementById('initial-loader-container');
  const root = document.getElementById('root');
  if (root && initialLoader) {
    root.style.display = 'none';
    initialLoader.style.display = 'flex';
  }
};

export const hideGlobalSpinner = () => {
  const initialLoader = document.getElementById('initial-loader-container');
  const root = document.getElementById('root');
  if (root && initialLoader) {
    root.style.display = 'block';
    initialLoader.style.display = 'none';
  }
};

export const getTranslation = async (language: SupportedLanguage) => {
  const files = [
    'addCommentModal.json',
    'api.json',
    'calendar.json',
    'captchaMemorial.json',
    'commentsManagement.json',
    'common.json',
    'contactUs.json',
    'facebookProfile.json',
    'footer.json',
    'helmet.json',
    'home.json',
    'leaveDetail.json',
    'login.json',
    'memorial.json',
    'myAccount.json',
    'myMemorials.json',
    'newMemorial.json',
    'pageNotFound.json',
    'pagination.json',
    'price.json',
    'productsWithPrice.json',
    'register.json',
    'signature.json',
    'start.json',
    'topbar.json',
    'translation.json',
    'upload.json',
  ];
  const promises = files.map((e) => import(`../views/App/translations/${language}/${e}`));
  const results = await Promise.allSettled(promises);
  let translation = {};
  results.forEach((e) => {
    if (e.status === 'rejected') {
      console.error('Error loading translation', e.reason);
    } else {
      translation = { ...translation, ...e.value.default };
    }
  });
  return translation;
};

export const getAllCountries = (): {
  isoCode: string;
  name: string;
  flag: string;
  phoneCode: string;
}[] => {
  const countriesToReturn: { isoCode: string; name: string; flag: string; phoneCode: string }[] =
    [];
  Object.keys(countries).forEach((countryCode: string) => {
    countriesToReturn.push({
      isoCode: countryCode,
      name: countries[countryCode as TCountryCode].native,
      flag: getEmojiFlag(countryCode as TCountryCode),
      phoneCode: '+' + countries[countryCode as TCountryCode].phone[0].toString(),
    });
  });

  return countriesToReturn.sort((a, b) => a.name.localeCompare(b.name));
};

export const goToWPHome = () => window.open('https://the-memorial.org/', '_blank');
