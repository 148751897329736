import { ReactElement, useEffect, useState, Suspense, useLayoutEffect } from 'react';
import { ConfigProvider } from 'antd';
import ReactGA from 'react-ga4';
import { Routes, Route, useLocation, Navigate, useNavigate } from 'react-router-dom';
import colors, { backgrounds, texts, fonts, alertColors } from '../../utils/theme';
import GlobalStyles from './GlobalStyles';
import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Loader from '../../DLS/Loader';
import loadTranslations from './loadTranslations';
import { useMemorial } from '../../context/memorial.provider';
import { jwtDecode } from 'jwt-decode';
import {
  IData,
  getDecodedToken,
  setProducts,
  updateUserInformation,
} from '../../context/memorial.actions';
import useApi from '../../utils/api';
import Topbar from '../../DLS/Topbar';
import { getTranslation, hideGlobalSpinner, showGlobalSpinner } from '../../utils';
import routes, { RouteType, plainRoutes } from './routes';

import './App.css';
import { Products } from '../../context/memorial.types';

const helmetContext = {};

ReactGA.initialize([{ trackingId: process.env.REACT_APP_GA4_MEASUREMENT_ID as string }]);

const ProtectedRouteWithToken = ({ children }: { children: ReactElement }) => {
  const { id } = useMemorial();

  if (id !== 0) {
    return children;
  }

  return <Navigate to="/" replace />;
};

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    firstName,
    id,
    isUserLoggedIntoSiteUnderConstruction,
    language,
    memorialDispatch,
    products,
  } = useMemorial();
  const { api } = useApi();
  const { i18n, t } = useTranslation();
  const [isCheckingToken, setIsCheckingToken] = useState<boolean>(true);
  const [currentHelmetInfo, setCurrentHelmetInfo] = useState<{
    title: string;
    description: string;
    keywords: string;
  } | null>(null);

  const showUnderConstructionView =
    !isUserLoggedIntoSiteUnderConstruction &&
    process.env.REACT_APP_SHOW_UNDER_CONSTRUCTION === 'true';

  useEffect(() => {
    const loadTranslationsAndRemoveSpinner = async () => {
      await loadTranslations();
      hideGlobalSpinner();
    };
    loadTranslationsAndRemoveSpinner();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('memorial_token');

    if (!id && token) {
      try {
        const decode = jwtDecode(token) as IData;
        if (decode.user_id) {
          memorialDispatch(getDecodedToken(decode));
        } else {
          localStorage.removeItem('memorial_token');
        }
      } catch (error) {
        localStorage.removeItem('memorial_token');
      }
    }
    setIsCheckingToken(false);
  }, [api, id, memorialDispatch]);

  useEffect(() => {
    if (id && !firstName) {
      api()
        .get('/user')
        .then((response) => {
          if (response.status === 200) {
            if (response.data?.data) {
              const { email, name, last_name, locale, image, phone, code, url_portal } =
                response.data.data;
              memorialDispatch(
                updateUserInformation({
                  email,
                  firstName: name,
                  image: image || '',
                  language: locale.replace('-', '_'),
                  lastName: last_name,
                  phone,
                  phoneCode: code,
                  urlToManagePaymentMethod: url_portal,
                })
              );
            }
          }
        });
    }
    setIsCheckingToken(false);
  }, [api, firstName, id, memorialDispatch]);

  useEffect(() => {
    const setAndLoadLanguage = async () => {
      const isLanguageLoaded = i18n.hasResourceBundle(language, 'translation');
      if (!isLanguageLoaded) {
        showGlobalSpinner();
        const translation = await getTranslation(language);
        i18n.addResourceBundle(language, 'translation', translation);
        hideGlobalSpinner();
      }
      i18n.changeLanguage(language);
    };

    setAndLoadLanguage();
  }, [i18n, language]);

  useEffect(() => {
    if (
      location.pathname === '/home' ||
      location.pathname === '/home/' ||
      (location.pathname === '/' && id)
    ) {
      navigate('/home/start?page=1&pageSize=9');
    }
  }, [location.pathname, navigate, id]);

  useLayoutEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname, title: 'Pageview' });

    const currentRoute = plainRoutes.find((route: RouteType) => {
      const finalPathToCompare = route.path.includes('/:') ? route.path.split('/:')[0] : route.path;

      if (finalPathToCompare === '/' && location.pathname !== '/') {
        return false;
      }

      if (
        (location.pathname.includes('/home/memorial/') &&
          finalPathToCompare === '/home/memorial') ||
        (location.pathname.includes('/home/add-comment/') &&
          finalPathToCompare === '/home/add-comment') ||
        (location.pathname.includes('/home/memorials/new') &&
          finalPathToCompare === '/home/memorials/new') ||
        (location.pathname.includes('/home/memorials/list') &&
          finalPathToCompare === '/home/memorials/list') ||
        (location.pathname.includes('/home/memorials/comments-management') &&
          finalPathToCompare === '/home/memorials/comments-management')
      ) {
        return true;
      } else if (!location.pathname.includes('home/memorial')) {
        return location.pathname.includes(finalPathToCompare);
      }
      return false;
    });

    if (!currentRoute || currentRoute?.withInternalHelmet) {
      setCurrentHelmetInfo(null);
    } else if (currentRoute?.isProtected || currentRoute?.withDefaultHelmet) {
      setCurrentHelmetInfo({
        title: t('helmet.home.title'),
        description: t('helmet.home.description'),
        keywords: t('helmet.home.keywords'),
      });
    } else if (currentRoute) {
      setCurrentHelmetInfo({
        title: t(`helmet.${currentRoute.key}.title`),
        description: t(`helmet.${currentRoute.key}.description`),
        keywords: t(`helmet.${currentRoute.key}.keywords`),
      });
    }
  }, [location, t]);

  useEffect(() => {
    if (showUnderConstructionView) {
      navigate('/under-construction');
    }
  }, [showUnderConstructionView, navigate]);

  useEffect(() => {
    if (location.pathname !== '/terms' && !products.premium.id) {
      api()
        .get('/stripe-products')
        .then((response) => {
          if (response.data?.data) {
            const products: Products = {
              animateImage: { id: '', currency: '' },
              basic: { id: '', currency: '' },
              premium: { id: '', currency: '' },
            };

            response.data.data.forEach(
              ({
                id,
                metadata,
              }: {
                id: string;
                metadata: { product: string; currency: string };
              }) => {
                if (metadata.product === 'animation-memorial') {
                  products.animateImage.id = id;
                  products.animateImage.currency = metadata.currency;
                } else if (metadata.product === 'basic-memorial') {
                  products.basic.id = id;
                  products.basic.currency = metadata.currency;
                } else if (metadata.product === 'premium-memorial') {
                  products.premium.id = id;
                  products.premium.currency = metadata.currency;
                }
              }
            );
            memorialDispatch(setProducts(products));
          }
        });
    }
  }, [api, location.pathname, memorialDispatch, products.premium.id]);

  return (
    <HelmetProvider context={helmetContext}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: colors.primary,
            colorPrimaryBgHover: colors.primary40,
            fontFamily: fonts.IBMPlexSansRegular,
            colorPrimaryHover: colors.primary40,
            colorError: alertColors.danger,
            colorSuccess: alertColors.success,
            colorWarning: alertColors.warning,
            colorBgContainerDisabled: backgrounds.bg1,
            fontSize: 16,
          },
          components: {
            Button: {
              colorText: texts.text1,
              colorBorder: backgrounds.bg1,
            },
            Input: {
              controlHeight: 40,
              borderRadius: 4,
              colorBorder: backgrounds.bg1,
            },
            Select: {
              controlHeight: 40,
              borderRadius: 4,
              colorBorder: backgrounds.bg1,
            },
            Checkbox: {
              colorPrimary: alertColors.success,
            },
            Radio: {
              colorPrimary: alertColors.success,
            },
          },
        }}
      >
        {/* bloquear rutas, ojo con las rutas del footer */}
        <GlobalStyles />
        {location.pathname !== '/' &&
          location.pathname !== '/terms' &&
          !showUnderConstructionView && <Topbar />}
        {currentHelmetInfo && (
          <Helmet>
            <title>{currentHelmetInfo.title}</title>
            <meta name="description" content={currentHelmetInfo.description} />
            <meta name="keywords" content={currentHelmetInfo.keywords} />
          </Helmet>
        )}
        <Suspense
          fallback={
            <div className="flex h-[calc(100vh-39px)] w-screen items-center justify-center md:h-[calc(100vh-80px)]">
              <Loader />
            </div>
          }
        >
          {!isCheckingToken && (
            <Routes>
              {routes.map(({ Component, key, path, children }) => {
                let parentRouteComponent = <Component />;
                return (
                  <Route key={key} path={path} element={parentRouteComponent}>
                    {children &&
                      children.map(({ Component, key, path, isProtected }) => {
                        let routeComponent = <Component />;
                        if (isProtected) {
                          return (
                            <Route
                              key={key}
                              path={path}
                              element={
                                <ProtectedRouteWithToken>{routeComponent}</ProtectedRouteWithToken>
                              }
                            />
                          );
                        }
                        return <Route key={key} path={path} element={<Component />} />;
                      })}
                  </Route>
                );
              })}
            </Routes>
          )}
        </Suspense>
      </ConfigProvider>
    </HelmetProvider>
  );
};

export default App;
