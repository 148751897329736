import { createUseStyles } from 'react-jss';

export default createUseStyles({
  loginModalWrapper: {
    '@media (max-width: 767px)': {},
  },

  loginModal: {
    '& .ant-modal-content': {
      '& .ant-modal-header': {
        border: 'none !important',
        height: '108px',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        '& .ant-modal-title': {
          fontSize: '31.25px !important',
        },
      },

      '& .ant-modal-body': {
        padding: '16px 48px 40px 48px !important',
        minHeight: '510px',
        '@media (max-width: 767px)': {
          padding: '16px 16px 32px 16px !important',
        },
        '@media (min-width: 768px)': {
          minHeight: '550px',
        },
      },
    },
    '@media (max-width: 767px)': {
      minWidth: '100vw !important',
      top: '0 !important',
      left: '0 !important',
      margin: 0,
      padding: 0,
      background: '#fff',
      '& .ant-modal-content': {
        overflowX: 'hidden !important',
        padding: '104px 16px 32px 16px !important',
        boxSizing: 'border-box',
        height: '100vh',
        width: '100vw',
        background:
          'linear-gradient(179.82deg, #95DDF4 -99.27%, rgba(58, 127, 169, 0.77257) -99.24%, #95DDF4 -99.21%, rgba(149, 221, 244, 0) 54.14%, rgba(149, 221, 244, 0.808731) 166.08%) !important',
        '& .ant-modal-close': {
          display: 'none',
        },
        '& .ant-modal-body': {
          position: 'relative',
          background: 'white',
          borderRadius: '0 0 8px 8px',
          padding: '16px !important',
        },
      },
    },
    '@media (min-width: 768px)': {
      maxWidth: '484px',
    },
  },
});
