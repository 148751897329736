import { createUseStyles } from 'react-jss';

export default createUseStyles({
  topBarDesktop: {
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      height: '4px',
    },
  },
});
