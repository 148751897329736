import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './views/App';
import './i18n';
import { MemorialProvider } from './context/memorial.provider';

import './index.css';
// require('dayjs/locale/ar');
require('dayjs/locale/bg');
require('dayjs/locale/ca');
require('dayjs/locale/cs');
require('dayjs/locale/cy');
require('dayjs/locale/da');
require('dayjs/locale/de');
require('dayjs/locale/el');
require('dayjs/locale/en');
require('dayjs/locale/es');
require('dayjs/locale/eu');
require('dayjs/locale/fi');
require('dayjs/locale/fr');
require('dayjs/locale/ga');
require('dayjs/locale/gl');
require('dayjs/locale/hu');
require('dayjs/locale/it');
require('dayjs/locale/ja');
require('dayjs/locale/ko');
require('dayjs/locale/nl');
require('dayjs/locale/pl');
require('dayjs/locale/pt');
require('dayjs/locale/ro');
require('dayjs/locale/ru');
require('dayjs/locale/sv');
require('dayjs/locale/tr');
require('dayjs/locale/uk');
require('dayjs/locale/zh');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Router>
    <MemorialProvider>
      <App />
    </MemorialProvider>
  </Router>
);
