import {
  GetDecodedToken,
  Logout,
  SetExpiredSessionAction,
  UpdateUserInformationAction,
} from './memorial.actions.types';
import * as memorialTypes from './memorial.actions.types';
import { SupportedLanguage } from '../types/SupportedLanguage';
import { Products } from './memorial.types';

export interface IData {
  roles: string[];
  user_id: number;
}

export const getToken = (token: string): memorialTypes.GetToken => {
  return {
    type: memorialTypes.GET_TOKEN,
    payload: token,
  };
};

export const getDecodedToken = (data: IData): GetDecodedToken => {
  return {
    type: memorialTypes.GET_DECODED,
    payload: {
      id: data.user_id,
      roles: data.roles,
    },
  };
};

export const setExpiredSession = (value: boolean): SetExpiredSessionAction => ({
  type: memorialTypes.SET_EXPIRED_SESSION,
  payload: { value },
});

export const logout = (): Logout => ({
  type: memorialTypes.LOGOUT,
});

export const updateUserInformation = (payload: {
  email: string;
  firstName: string;
  image: string;
  language: SupportedLanguage;
  lastName: string;
  phone: string;
  phoneCode: string;
  urlToManagePaymentMethod?: string;
}): UpdateUserInformationAction => ({
  type: memorialTypes.UPDATE_USER_INFORMATION,
  payload,
});

export const setUserLoggedIntoSiteUnderConstruction =
  (): memorialTypes.SetUserLoggedIntoSiteUnderConstruction => ({
    type: memorialTypes.SET_USER_LOGGED_INTO_SITE_UNDER_CONSTRUCTION,
    payload: {},
  });

export const setProducts = (products: Products): memorialTypes.SetProducts => {
  return {
    type: memorialTypes.SET_PRODUCTS,
    payload: { products },
  };
};
