import { useRef } from 'react';
import { ReactComponent as DownFilled } from '../../../assets/images/downFilled.svg';
import i18next from 'i18next';
import { menuList } from '../LanguageSelector';
import { useTranslation } from 'react-i18next';

const LanguageFlag = ({
  isOpenMenu = false,
  toggleMenu,
}: {
  isOpenMenu?: boolean;
  toggleMenu: () => void;
}) => {
  const { i18n } = useTranslation();
  const canShowFlag = useRef<boolean>(i18next.isInitialized);
  const currentLanguage = i18n.language.toLowerCase() as keyof typeof menuList;

  return (
    <div
      className="flex cursor-pointer select-none items-center justify-center rounded-[8px] bg-bg4 p-[8px] hover:bg-bg3"
      onClick={toggleMenu}
    >
      {canShowFlag.current ? (
        <img
          src={menuList[currentLanguage].flag}
          alt=""
          role="button"
          className="h-[24px] max-h-[48px] w-[24px] rounded-full"
        />
      ) : (
        <div className="h-[24px] max-h-[48px] w-[24px] rounded-full bg-bg1" />
      )}
      <DownFilled className={`ml-[16px] ${isOpenMenu ? 'rotate-180' : ''}`} />
    </div>
  );
};

export default LanguageFlag;
