import { Drawer } from 'antd';
import { menuList } from '../Topbar/LanguageSelector';
import { useTranslation } from 'react-i18next';
import { CheckSolid, XmarkSolid } from '../../utils/icons';
import Search from '../Search';
import { useEffect, useState } from 'react';
import { SupportedLanguage } from '../../types/SupportedLanguage';
import { getTranslation, hideGlobalSpinner, showGlobalSpinner } from '../../utils';

const DrawerChangeLanguageMobile = ({ onClose }: { onClose: () => void }) => {
  const [textToFilter, setTextToFilter] = useState<string>('');
  const [options, setOptions] = useState<{ language: string; title: string }[]>([]);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language.toLowerCase() as keyof typeof menuList;

  useEffect(() => {
    setOptions(
      Object.keys(menuList)
        .filter((key) =>
          menuList[key as keyof typeof menuList].title
            .toLocaleLowerCase()
            .includes(textToFilter.toLocaleLowerCase())
        )
        .map((key) => ({ title: menuList[key as keyof typeof menuList].title, language: key }))
    );
  }, [textToFilter]);

  const onChangeLanguage = async (language: SupportedLanguage) => {
    const isLanguageLoaded = i18n.hasResourceBundle(language, 'translation');
    if (!isLanguageLoaded) {
      showGlobalSpinner();
      const translation = await getTranslation(language);
      i18n.addResourceBundle(language, 'translation', translation);
      hideGlobalSpinner();
    }
    i18n.changeLanguage(language);
    setTextToFilter('');
    onClose();
  };

  return (
    <>
      <Drawer
        title={t('common.changeLanguage')}
        placement="bottom"
        onClose={onClose}
        open
        closeIcon={<XmarkSolid />}
        rootClassName="drawerMobile"
        size="large"
      >
        <div className="pb-[8px]">
          <Search allGray placeholder={t('home.search')} onChange={setTextToFilter} />
        </div>
        <div className="overflow-y-scroll">
          {options.map(({ language, title }) => (
            <div
              onClick={() => onChangeLanguage(language)}
              className="flex justify-between py-[8px]"
              key={`language-${language}`}
            >
              {title}
              {currentLanguage === language && (
                <div className="[&>svg>path]:fill-success">
                  <CheckSolid />
                </div>
              )}
            </div>
          ))}
          {options.length === 0 && (
            <p className="mt-[16px] text-center">{t('common.withoutResults')}</p>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default DrawerChangeLanguageMobile;
